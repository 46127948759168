import { useRouteQuery } from '@vueuse/router'
import type { UnwrapRef } from 'vue'

export function useMovieUrl(params: { mfiUrl: string }) {
  const { mfiUrl } = params

  const localePath = useLocalePath()

  const { locale } = useI18n()
  const translations = {
    fr: '/films',
    en: '/movies'
  }
  return localePath(`${translations[locale.value]}${mfiUrl}`)
}

export function transformMfiUrl(mfiUrl: string) {
  return mfiUrl?.startsWith('/') ? mfiUrl.slice(1) : mfiUrl
}

export function useArticleUrl(params: { mfiUrl: string }) {
  const { mfiUrl } = params

  const localePath = useLocalePath()

  return localePath({ name: 'articles-slug', params: { slug: transformMfiUrl(mfiUrl) } })
}

export function useArtistUrl(params: { mfiUrl: string }) {
  const { mfiUrl } = params

  const localePath = useLocalePath()

  return localePath({ name: 'artists-slug', params: { slug: transformMfiUrl(mfiUrl) } })
}

export function useAuthorUrl(params: { mfiUrl: string }) {
  const { mfiUrl } = params

  const localePath = useLocalePath()

  return localePath({ name: 'authors-slug', params: { slug: transformMfiUrl(mfiUrl) } })
}

export function useSSRSafeQuery(name: string) {
  const reactiveQueryValue = useRouteQuery(name)

  return computed(() => {
    let queryValue: UnwrapRef<typeof reactiveQueryValue> = null

    if (import.meta.server) {
      queryValue = useRequestURL().searchParams.get(name) ?? ''
    } else if (typeof reactiveQueryValue.value === 'string') {
      queryValue = reactiveQueryValue.value
    }

    return queryValue
  })
}

export function usePageName() {
  const name = useRoute().name.replace(/___(fr|en)/g, '')

  switch (name) {
    case 'index':
      return 'home'
    case 'movies-weekly-releases':
    case 'movies-upcoming-releases':
    case 'movies-featured':
      return 'films list'
    case 'movies-slug':
      return 'film page'
    case 'articles-slug':
      return 'article page'
    case 'search':
      return 'recherche'
    case 'lists-id':
      return 'liste page'
    case 'about-us':
      return 'à propos'
    case 'tv-schedule':
      return 'horaire de télé'
    case 'articles':
      return 'articles list'
    case 'artists-slug':
      return 'artiste page'
    case 'authors-slug':
      return 'auteur page'
  }
}
